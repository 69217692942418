<template>
    <div class="restart-accounts-widget">
        <div class="col-12" v-if="users.length>0">
        Select A User
         <b-form-select class="mb-3" :options="users" value-field="id" text-field="username" @change="userChange" :disabled="isChanging || isChangingAccount || isChangingPage"></b-form-select>
         </div>
         <small v-if="users.length==0">Users loading or unable to load</small>
        <div class="row">
            <div class="col-12">
                <b-card no-body class="tabbed-card">
                    <b-tabs card>
                        <b-tab title="User Details" active>
                            <b-form-group label="Username:">
                                <b-form-input v-model="currentUser.username" :disabled="isChanging" ></b-form-input>
                                <b-button variant="primary" class="col-1" :disabled="isChanging" @click.prevent="changeUsername()">
                                    <template v-if="isChanging">
                                        <b-spinner small></b-spinner>
                                    </template>
                                    Change
                                </b-button>
                                <b-alert class="col-3" v-model="usernameAlert" variant="danger" dismissible>Failed to change username</b-alert>
                                <template v-if="usernameAlert===null"><b-alert show class="col-3" variant="success" dismissible>Name changed successfully</b-alert></template>
                            </b-form-group>
                             <b-form-group label="Email:">
                                <b-form-input v-model="currentUser.email" :disabled="isChanging"></b-form-input>
                                <b-button variant="primary" class="col-1" :disabled="isChanging" @click.prevent="changeEmail()">
                                    <template v-if="isChanging">
                                        <b-spinner small></b-spinner>
                                    </template>
                                    Change
                                </b-button>
                                <b-alert class="col-3" v-model="emailAlert" variant="danger" dismissible>Failed to change Email</b-alert>
                                <template v-if="emailAlert===null"><b-alert show class="col-3" variant="success" dismissible>Email changed successfully</b-alert></template>
                            </b-form-group>
                            <div class="row">
                                <b-form-group class="col-sm-6" label="First Name:">
                                    <b-form-input v-model="currentUser.firstName" :disabled="isChanging"></b-form-input>
                                    <b-button variant="primary" class="col-2" :disabled="isChanging" @click.prevent="changeFirstName()">
                                        <template v-if="isChanging">
                                            <b-spinner small></b-spinner>
                                        </template>
                                        Change
                                    </b-button>
                                    <b-alert class="col-6" v-model="firstNameAlert" variant="danger" dismissible>Failed to change First Name</b-alert>
                                    <template v-if="firstNameAlert===null"><b-alert show class="col-6" variant="success" dismissible>First Name changed successfully</b-alert></template>
                                </b-form-group>
                                <b-form-group class="col-sm-6" label="Last Name:">
                                    <b-form-input v-model="currentUser.lastName" :disabled="isChanging"></b-form-input>
                                    <b-button variant="primary" class="col-2" :disabled="isChanging" @click.prevent="changeLastName()">
                                        <template v-if="isChanging">
                                            <b-spinner small></b-spinner>
                                        </template>
                                        Change
                                    </b-button>
                                    <b-alert class="col-6" v-model="lastNameAlert" variant="danger" dismissible>Failed to change Last Name</b-alert>
                                    <template v-if="lastNameAlert===null"><b-alert show class="col-6" variant="success" dismissible>Last Name changed successfully</b-alert></template>
                                </b-form-group>
                            </div>
                            <b-form-group label="Password:">
                                <b-form-input v-model="currentUser.password" :disabled="isChanging"></b-form-input>
                                
                                <b-button v-if="passwordChange==true" variant="primary" class="col-1" :disabled="isChanging" @click.prevent="passwordChange=false">
                                    Change
                                </b-button>
                                <div class="row col-1" v-else>
                                    Are you sure?
                                    <b-button variant="danger" class="col-6" :disabled="isChanging" @click.prevent="passwordChange=true;changePassword()">
                                        <template v-if="isChanging">
                                            <b-spinner small></b-spinner>
                                        </template>
                                        Yes
                                    </b-button>
                                    <b-button variant="secondary" class="col-6" :disabled="isChanging" @click.prevent="passwordChange=true">
                                        No
                                    </b-button>
                                </div>
                                
                                <b-alert class="col-6" v-model="passwordAlert" variant="danger" dismissible>Failed to change Password</b-alert>
                                <template v-if="passwordAlert===null"><b-alert show class="col-6" variant="success" dismissible>Password changed successfully</b-alert></template>
                            </b-form-group>
                            <b-form-group label="Active:" class="col-sm-1">
                                <b-form-select v-model="currentUser.active" class="text-center" :options="[0,1]" value-field="id" text-field="active" :disabled="isChanging"></b-form-select>
                                <b-button variant="primary" class="col-12" :disabled="isChanging" @click.prevent="changeActive()">
                                    <template v-if="isChanging">
                                        <b-spinner small></b-spinner>
                                    </template>
                                    Change
                                </b-button>
                            </b-form-group>
                            <b-alert class="col-6" v-model="activeAlert" variant="danger" dismissible>Failed to change Active</b-alert>
                            <template v-if="activeAlert===null"><b-alert show class="col-6" variant="success" dismissible>Active changed successfully</b-alert></template>
                        </b-tab>
                        <b-tab title="Allowed Pages">
                            <b-alert class="col-6" v-model="pageAlert" variant="danger" dismissible>Failed to change Page</b-alert>
                            <template v-if="pageAlert===null"><b-alert show class="col-6" variant="success" dismissible>Page changed successfully</b-alert></template>
                            <div class="row mb-2" v-for="page in pages" :key="page.name">
                                <div class="col-3 text-left">
                                    {{page.name}}
                                </div>
                                <div class="col-9 text-left">
                                    <b-button :variant="(userPages.find(x=> (x.userId == currentUser.id && x.pageId == page.id))?'danger':'success')" @click.prevent="togglePage(page.id)" :disabled="isChangingPage" :title="(userPages.find(x=> (x.userId == currentUser.id && x.pageId == page.id))?'Disable ':'Enable ')+page.name">{{userPages.find(x=> (x.userId == currentUser.id && x.pageId == page.id))?"Disable":"Enable"}}</b-button>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Allowed Accounts">
                            <b-alert class="col-6" v-model="accountAlert" variant="danger" dismissible>Failed to change Account</b-alert>
                            <template v-if="accountAlert===null"><b-alert show class="col-6" variant="success" dismissible>Account changed successfully</b-alert></template>
                            <div class="row mb-2" v-for="account in accounts" :key="account.id">
                                <div class="col-3 text-left">
                                    {{account.displayName}}
                                </div>
                                <div class="col-9 text-left">
                                    <b-button :variant="(userAccounts.find(x=> (x.userId == currentUser.id && x.accountId == account.id))?'danger':'success')" @click.prevent="toggleAccount(account.id)" :disabled="isChangingAccount" :title="(userAccounts.find(x=> (x.userId == currentUser.id && x.accountId == account.id))?'Disable ':'Enable ') + account.displayName">{{userAccounts.find(x=> (x.userId == currentUser.id && x.accountId == account.id))?"Disable":"Enable"}}</b-button>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'manage-user-widget',
    data() {
        return {
            hasSetupListeners: false,
            users: [],
            currentUser:{username:null,email:null,id:null,firstName:null,lastName:null,password:null,active:null},
            newPassword: null,
            isChanging: false,
            isChangingAccount:false,
            isChangingPage: false,
            usernameAlert:false,
            emailAlert:false,
            firstNameAlert:false,
            lastNameAlert:false,
            accountAlert:false,
            pageAlert:false,
            passwordAlert:false,
            activeAlert:false,
            passwordChange:true,
            pages: [],
            userPages: [],
            accounts: [],
            userAccounts: [],
            awaitingResponse: false
        };
    },
    mounted() {
        this.requests();
    },
    destroyed() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('manageusers', this.getAllData);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('manageusers', this.getAllData);
                this.hasSetupListeners = true;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"getdata"
                }
            });

        },
        getAllData(event){
            if(event.request.args.request == "getdata"){
                this.users = event.response[0].sort((x,y) => {return x.username.toLowerCase() > y.username.toLowerCase() ? 1:-1});
                this.pages = event.response[1];
                this.accounts = event.response[2];
                this.userAccounts = event.response[3];
                this.userPages = event.response[4];
            }else if(event.request.args.request == "changeUsername"){
                this.isChanging = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.usernameAlert = true;
                }else{
                    this.usernameAlert = null;
                    this.requests();
                }
            }else if(event.request.args.request == "changeActive"){
                this.isChanging = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.activeAlert = true;
                }else{
                    this.activeAlert = null;
                    this.requests();
                }
            }else if(event.request.args.request == "changeEmail"){
                this.isChanging = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.emailAlert = true;
                }else{
                    this.emailAlert = null;
                    this.requests();
                }
            }else if(event.request.args.request == "changeFirstName"){
                this.isChanging = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.firstNameAlert = true;
                }else{
                    this.firstNameAlert = null;
                    this.requests();
                }
            }else if(event.request.args.request == "changeLastName"){
                this.isChanging = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.lastNameAlert = true;
                }else{
                    this.lastNameAlert = null;
                    this.requests();
                }
            }else if(event.request.args.request == "changePassword"){
                this.isChanging = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.passwordAlert = true;
                }else{
                    this.passwordAlert = null;
                    this.requests();
                }
            }else if(event.request.args.request == "toggleAccount"){
                this.isChangingAccount = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.accountAlert = true;
                }else{
                    this.accountAlert = null;
                    if(this.userAccounts.find(x=> (x.userId == event.request.args.userId && x.accountId == event.request.args.accountId))){
                        this.userAccounts = this.userAccounts.filter(x=> (x.UserId != event.request.args.userId && x.accountId != event.request.args.accountId));
                    }else{
                        this.userAccounts.push({
                        userId:event.request.args.userId,
                        accountId:event.request.args.accountId
                        })
                    }
                }
            }else if(event.request.args.request == "togglePage"){
                this.isChangingPage = false;
                if(event.response.toString().toLowerCase() == "false"){
                    this.pageAlert = true;
                }else{
                    this.pageAlert = null;
                    if(this.userPages.find(x=> (x.userId == event.request.args.userId && x.pageId == event.request.args.pageId))){
                        this.userPages = this.userPages.filter(x=> (x.UserId != event.request.args.userId && x.pageId != event.request.args.pageId));
                    }else{
                        this.userPages.push({
                        userId:event.request.args.userId,
                        pageId:event.request.args.pageId
                        })
                    }
                }
            }
            
        },
        userChange(id){
            //update local user data
            this.currentUser.id = id
            this.currentUser.email = this.users.find(x => x.id == id).email;
            this.currentUser.username = this.users.find(x => x.id == id).username;
            this.currentUser.firstName = this.users.find(x => x.id == id).firstName;
            this.currentUser.lastName = this.users.find(x => x.id == id).lastName;
            this.currentUser.active = this.users.find(x => x.id == id).active;
        },
        changeUsername(){
            this.isChanging = true;
            this.usernameAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"changeUsername",
                    username:this.currentUser.username,
                    id: this.currentUser.id
                }
            })
        },
        changeEmail(){
            this.isChanging = true;
            this.emailAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"changeEmail",
                    email:this.currentUser.email,
                    id: this.currentUser.id
                }
            })
        },
        changePassword(){
            if(this.currentUser.password == null || this.currentUser.password == ""){return}
            this.isChanging = true;
            this.passwordAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"changePassword",
                    password:this.currentUser.password,
                    userId: this.currentUser.id
                }
            }) 
        },
        changeFirstName(){
            this.isChanging = true;
            this.firstNameAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"changeFirstName",
                    firstName:this.currentUser.firstName,
                    id: this.currentUser.id
                }
            })
        },
        changeLastName(){
            this.isChanging = true;
            this.lastNameAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"changeLastName",
                    lastName:this.currentUser.lastName,
                    id: this.currentUser.id
                }
            })
        },
        changeActive(){
            this.isChanging = true;
            this.activeAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"changeActive",
                    active:this.currentUser.active,
                    id: this.currentUser.id
                }
            })
        },
        toggleAccount(id){
            this.isChangingAccount = true;
            this.accountAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"toggleAccount",
                    accountId:parseInt(id),
                    userId: parseInt(this.currentUser.id),
                    toggle:(this.userAccounts.find(x=> (x.userId == this.currentUser.id && x.accountId == id))?false:true)
                }
            })
        },
        togglePage(id){
            this.isChangingPage = true;
            this.pageAlert = false;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'ManageUsers',
                args: {
                    request:"togglePage",
                    pageId:parseInt(id),
                    userId: parseInt(this.currentUser.id),
                    toggle: (this.userPages.find(x=> (x.userId == this.currentUser.id && x.pageId == id))?false:true)
                }
            })
        }
    }
}
</script>